import { ReactNode, useEffect, useState } from "react";
import ImgMenu from "./menu.svg";
import "./style.css";

interface DrawerProps {
    children: ReactNode
}

export default function Drawer({ children}: DrawerProps) {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        const drawer = document.getElementById('drawer');
        const button = document.getElementById('buttonDrawer');

        if (drawer && button) {
            document.addEventListener('click', (event: MouseEvent) => {
                const target = event.target as HTMLElement;

                if (!drawer.contains(target) && !button.contains(target)) {
                    setOpen(false);
                }
            });
        }
    });
    return(
        <>
            <ButtonDrawer setOpen={() => setOpen(!open)}/>
            { open ? 
                <div id="drawer">
                    {children}
                </div>
                : null
            }
            
        </>
    );
}

interface ButtonDrawerProps {
    setOpen: () => void
}


export function ButtonDrawer({ setOpen }:ButtonDrawerProps){
    return (
        <span id="buttonDrawer" onClick={setOpen}>
            <img src={ImgMenu} alt="Menu" />
        </span>
    )
}