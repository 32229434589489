import { ImgHTMLAttributes, ReactNode } from "react";
import fotoPerfil from "./perfil_ismael.png";

interface PerfilProps extends ImgHTMLAttributes<HTMLImageElement>{
    width: number,
    titulo?: string,
    flutuante?: boolean,
    children?: ReactNode,
}

export function Perfil({ width, titulo, flutuante, children, ...rest }: PerfilProps){
    return (
        <span id="perfil">
            <img style={{
                width: 52,
                backgroundColor: "var(--corTransparente)",
                borderRadius: "100%",
                padding: 0,
            }}  src={fotoPerfil} alt="Ismael Guedes" {...rest}/>
            <h1 className="t1" style={{
                backgroundColor: flutuante ? "var(--corTransparente)" : "transparent", 
                padding: flutuante ? "10px 20px" : "", 
                borderRadius: 15
            }}>
                <b>{titulo}</b>
                {children}
            </h1>
        </span>
    );
}