import Menu from "./components/Menu";
import Options from "./components/Options";
import { Book, Chapter, Page, Topico } from "./pagination";
import Slider from "./components/Slider";
import Timeline from "./components/Timeline";
import { useEffect, useState } from "react";
import { IconsTech } from "./components/IconTech";
import { Perfil } from "./components/Perfil";
import { Header } from "./components/Header";
import Recuo from "./components/Recuo";
import TextEffect from "./components/TextEffect";
import gifIdeia from "./assets/images/ideia_animada.gif";
import gifSolucao from "./assets/images/solucao_animada.gif";
import gifExplorar from "./assets/images/explorar_animada.gif";
import logoIfpi from "./assets/images/logo_ifpi.png";
import CardInfo from "./components/CardInfo";
import { Contacts } from "./components/Contact";
import Drawer from "./components/Drawer";

function App() {
    const [chapter, setChapter] = useState(0);
    const [page, setPage] = useState(0);
    const [drawer, setDrawer] = useState(false);
    const updateSize = () => {
        let bg = window.matchMedia('(max-width: 1300px)').matches;
        if(bg){
            setDrawer(true);
        }
        else {
            setDrawer(false);
        }
    };

    const chapters = [
        {
            nome: "Inicio",
            pages: [
                <Perfil width={800}>
                    <b>
                        <TextEffect texts={['Olá, sou Ismael!']} velocity={.1}/>
                    </b>
                </Perfil>,
                <>
                    <Header>
                        <Perfil width={300} titulo="Inicio" flutuante />
                    </Header>
                    <TextEffect texts={['Seja bem-vindo ao', 'Meu portfolio interativo!']} velocity={.1} fontSize={17} fontWeight="bolder"/>
                </>,
                <>
                    <Header>
                        <Perfil width={300} titulo="Inicio" flutuante />
                    </Header>
                    <Topico>
                        <img src={gifIdeia} alt="Gif Ideia Animada" width={150}/>
                        <TextEffect texts={['Posso transformar as suas', 'ideias em realidade!']} velocity={.1} fontSize={17} fontWeight="bolder"/>
                    </Topico>
                </>,
                <>
                    <Header>
                        <Perfil width={300} titulo="Inicio" flutuante />
                    </Header>
                    <Topico>
                        <img src={gifSolucao} alt="Gif Solução Animada" width={150}/>
                        <TextEffect texts={['Adaptando e integrando', ' diversos modelos de projetos']} velocity={.1} fontSize={17} fontWeight="bolder"/>
                    </Topico>
                </>,
                <>
                    <Header>
                        <Perfil width={300} titulo="Inicio" flutuante />
                    </Header>
                    <Topico>
                        <img src={gifExplorar} alt="Gif Explorar Animado" width={150}/>
                        <TextEffect texts={['Aqui você encontrou', 'o que você buscava!']} velocity={.1} fontSize={17} fontWeight="bolder"/>
                    </Topico>
                </>,
            ],
        },
        {
            nome: "Meus Projetos",
            pages: [
                <>
                    <Header>
                        <Perfil width={300} titulo="Meus Projetos" flutuante />
                    </Header>
                    <Topico>
                        <TextEffect texts={['Veja meus principais projetos', 'e como eles podem ser utéis', 'para você']} velocity={.1} fontSize={17} fontWeight="bolder"/>
                    </Topico>
                </>,
                <>
                    <Header>
                        <Perfil width={300} titulo="Meus Projetos / Esportix" flutuante />
                    </Header>
                    <Slider width={400} height={250} timeStep={4} images={[
                        "https://lh3.googleusercontent.com/d/1v2f6uNxcZ21Ln0DHuqoQUaTJwVOiEwPJ=s1200",
                        "https://lh3.googleusercontent.com/d/1LxJDO4O3-N7Tvpp7aSUjepbfLyDf-OTG=s1200",
                        "https://lh3.googleusercontent.com/d/1axtou5EBADOLgGV_yOOSgQhmZIcRb2Lr=s1200",
                        "https://lh3.googleusercontent.com/d/1BkMOspYQy10VIcmdCj-6tdtiUuOCqujZ=s1200",
                    ]} />
                    <article className="resume">
                        <h1 id="title">Esportix</h1>
                        <br />
                        <p id="description">
                            <Recuo />O <b className="link">Esportix</b> é um sistema web completo de
                            gestão de eventos esportivos, projetado para facilitar a organização e o gerenciamento de partidas
                            e competições. Com o Esportix, os usuários podem criar e gerenciar eventos esportivos, definindo
                            datas, locais, categorias, e regras específicas para cada partida. Esse sistema foi uma proposta de atividade como
                            teste de conhecimento.
                        </p>
                        <br />
                        <section id="technologies">
                            <h1>Tecnologias</h1>
                            <ul>
                                {IconsTech.python}
                                {IconsTech.django}
                                {IconsTech.html}
                                {IconsTech.css}
                                {IconsTech.javascript}
                                {IconsTech.mysql}
                            </ul>
                        </section>
                    </article>
                </>,
                <>
                    <Header>
                        <Perfil width={300} titulo="Meus Projetos / Agroshow" flutuante />
                    </Header>
                    <Slider width={206} height={456} timeStep={4} images={[
                        "https://lh3.googleusercontent.com/d/1a8wQx6NWWqq9ZfZ_I9rgsCQooHUYtD72=s1200",
                        "https://lh3.googleusercontent.com/d/1jDrnx7sOCFxQB98kJDBOYBTf5jtCp7x6=s1200",
                        "https://lh3.googleusercontent.com/d/1qxjYLSKoeVuVi4OV51GvvsEAP1RKQY2h=s1200",
                        "https://lh3.googleusercontent.com/d/1_PidsYK-InBsnAjBWlC3nmuRpeAGCTNs=s1200",
                        "https://lh3.googleusercontent.com/d/1TMhC8Rk2sR1GcDuj4Bur2a8gctP_aohh=s1200",
                    ]} />
                    <article className="resume">
                        <h1 id="title">Agroshow</h1>
                        <br />
                        <p id="description">
                            <Recuo />O <b className="link">Agroshow</b> foi um aplicativo projetado por uma equipe de alunos
                            para facilitar a organização, gestão e participação em exposições de animais, leilões e eventos relacionados ao setor agropecuário em Corrente - PI (ExpoCorrente 2023). Com recursos avançados 
                            e uma interface intuitiva, o AgroShow visa transformar a forma como esses eventos são planejados e executados. <br /><br /><Recuo />Um parceria 
                            entre <a href="https://www.ifpi.edu.br/corrente" target="blank" className="link">Instituto Federal do 
                            Piauí</a> e <a href="https://uespi.br/tag/corrente/" target="blank" className="link">Universidade Estadual do Piauí</a> em Corrente Piauí. A equipe alunos 
                            foi formada por @ismael_vguedes, @viniciusmarques.dev, @deejhaydk e @cristian_paulo4 sobre a orientação do professores @fgsantosti e @cintia.araujo_02.
                            <br/><br/>Acesse aqui e veja o projeto <a href="https://play.google.com/store/apps/details?id=com.fgsantos.agroshow" target="blank" className="link">com.fgsantos.agroshow</a>
                        </p>
                        <br />
                        <section id="technologies">
                            <h1>Tecnologias</h1>
                            <ul>
                                {IconsTech.dart}
                                {IconsTech.flutter}
                                {IconsTech.firebase}
                            </ul>
                        </section>
                    </article>
                </>,
                <>
                    <Header>
                        <Perfil width={300} titulo="Meus Projetos / ZSSN" flutuante />
                    </Header>
                    <Slider width={410} height={225} timeStep={4} images={[
                        "https://lh3.googleusercontent.com/d/141HQuE2lpLKuB8Ziogr_Tu_1haNAPebu=s1200",
                        "https://lh3.googleusercontent.com/d/1gMmaIC9oFlQQ8UXL7MShaLAgHnVzaRRS=s1200",
                        "https://lh3.googleusercontent.com/d/133_gX60Y17WIktG19U9Agvitoja_HM9N=s1200",
                        "https://lh3.googleusercontent.com/d/18bHhYf1iNvwyIjdlStS9cwqmjrkE8Tfr=s1200",
                    ]} />
                    <article className="resume">
                        <h1 id="title">Zombie Survival Social Network - ZSSN</h1>
                        <br />
                        <p id="description">
                            <Recuo />O <b className="link">ZSSN</b> é uma aplicacao web ficticia que se basea em elementos 
                            graficos do jogo eletronico Minecraft. O sistema e feito em python utilizando o framework django. Além disso, a aplicação também e uma API que retorna informações 
                            sobre os supostos sobreviventes e recursos.
                            <br/><br/>Acesse aqui e veja o projeto <a href="https://www.dephino.com/" target="blank" className="link">dephino.com</a>
                        </p>
                        <br />
                        <section id="technologies">
                            <h1>Tecnologias</h1>
                            <ul>
                                {IconsTech.python}
                                {IconsTech.django}
                                {IconsTech.html}
                                {IconsTech.css}
                                {IconsTech.javascript}
                                {IconsTech.sqlite}
                            </ul>
                        </section>
                    </article>
                </>,
                <>
                    <Header>
                        <Perfil width={300} titulo="Meus Projetos / Agrosul Piauí" flutuante />
                    </Header>
                    <Slider width={400} height={250} timeStep={4} images={[
                        "https://lh3.googleusercontent.com/d/1GqM6Ak4rfOOfKRdTKTIa6r_os-HZH04-=s1200",
                        "https://lh3.googleusercontent.com/d/1QhkwMBjWdnZfic7bDSv7ePykHun9E7Cu=s1200",
                        "https://lh3.googleusercontent.com/d/19jZwNwFSTFv5SWqRTKvJ2uWesiw9gKru=s1200",
                        "https://lh3.googleusercontent.com/d/1IavOL0kb5dFbesyFMIccLmkyr9PJn-Xp=s1200",
                        "https://lh3.googleusercontent.com/d/1PEmHWmvFckUUZyueUgFTLxKTIzxhpkxl=s1200",
                        "https://lh3.googleusercontent.com/d/1LDi3U_WyFkxO3FmT5ZcB_KuYhNlzMNCf=s1200",
                    ]} />
                    <article className="resume">
                        <h1 id="title">Agrosul Piauí Familiar 2017</h1>
                        <br />
                        <p id="description">
                            <Recuo />O <b className="link">Sistema Agrosul Piauí</b> foi desenvolvido especificamente para a 1ª Feira da Agricultura 
                            Familiar do Sul do Piauí e o 1º Encontro de Agroecologia do Sul do Piauí, uma iniciativa do Campus Corrente do <b>IFPI</b>, em  
                            parceria com o <a href="https://www.gov.br/mda/pt-br" target="blank" className="link">
                            Ministério do Desenvolvimento Agrário e Agricultura Familiar (MDA)</a>.
                            <br/><br/><Recuo />Esse sistema foi responsável pela gestão dos inscritos participantes do evento, permitindo a inscrição 
                            em minicursos, oficinas e outras atividades. Veja mais sobre o evento em <a 
                            href="https://www.ifpi.edu.br/corrente/noticias/ifpi-e-mda-realizam-1a-feira-da-agricultura-familiar-e-1o-encontro-de-agroecologia-do-sul-do-piaui"
                            target="blank" className="link">IFPI e MDA realizam 1ª Feira da Agricultura Familiar...</a>

                            <br/><br/>Acesse aqui e veja o projeto <a href="https://www.agrosulpiaui.com.br/" target="blank" className="link">agrosulpiaui.com.br</a> e 
                            foi desenvolvido por @ismael_vguedes, @heitorlouzeiro e @fgsantosti
                        </p>
                        <br />
                        <section id="technologies">
                            <h1>Tecnologias</h1>
                            <ul>
                                {IconsTech.python}
                                {IconsTech.django}
                                {IconsTech.html}
                                {IconsTech.css}
                                {IconsTech.javascript}
                                {IconsTech.postgresql}
                            </ul>
                        </section>
                    </article>
                </>,
                <>
                    <Header>
                        <Perfil width={300} titulo="Meus Projetos / Agrowise" flutuante />
                    </Header>
                    <Slider width={236} height={456} timeStep={4} images={[
                        "https://lh3.googleusercontent.com/d/1vFWf1vpnKcEHeSTe1L5nLjs6R0i4z9Jy=s1200",
                        "https://lh3.googleusercontent.com/d/1sJBTQrNfQU5vP5t0NnrBQTxfoeKwAmo2=s1200",
                        "https://lh3.googleusercontent.com/d/1z9JfPqL1ygBejNqZGYj3I46AHl54Rm6x=s1200",
                        "https://lh3.googleusercontent.com/d/1BiF9ZxrDbUjvAe55wya9dhUso9kzbWXG=s1200",
                    ]}
                    />
                    <article className="resume">
                        <h1 id="title">Agrowise</h1>
                        <br />
                        <p id="description">
                            <Recuo />O <b className="link">Agrowise</b> foi um aplicativo de recomendação de 
                            culturas gratuito para utilização, que faz parte do meu trabalho de conclusão de curso de Análise e Desenvolvimento de Sistemas 2023.
                            Ele é capaz de fazer recomendações de quais culturas são adequadas para o plantio em áreas agrícolas.
                            <br/><br/>Ele utiliza um modelo de aprendizado de máquina para fazer suas recomendações, com base em 
                            parâmetros com nitrogênio, potássio, fosforo, ph, umidade e pluviosidade.
                            <br/><br/>Atualmente ele está em manutenção, e logo será lançada uma nova atualização na Google Play Store.
                        </p>
                        <br />
                        <section id="technologies">
                            <h1>Tecnologias</h1>
                            <ul>
                                {IconsTech.dart}
                                {IconsTech.flutter}
                                {IconsTech.python}
                                {IconsTech.django}
                                {IconsTech.scikitlearn}
                            </ul>
                        </section>
                    </article>
                </>,
            ]
        },
        {
            nome: "Quem eu sou?",
            pages: [
                <>
                    <Header>
                        <Perfil width={300} titulo="Quem eu sou?" flutuante />
                    </Header>
                    <Topico>
                        <TextEffect texts={['Agora, apresento a você', 'um pouco sobre mim, e', 'minha formação']} velocity={.1} fontSize={17} fontWeight="bolder"/>
                    </Topico>
                </>,
                <>
                    <Header>
                        <Perfil width={300} titulo="Quem eu sou?" flutuante />
                    </Header>
                    <CardInfo icon={logoIfpi} borderColor="#009143" title="TÉCNICO EM INFORMÁTICA" subtitle="Instituto Federal do Piauí" location="Corrente-PI" period="2017 a 2020">
                        <Recuo/>Durante meu ensino médio, fiz curso <b>técnico em informática</b>, onde tive meu primeiro contato real com a 
                        <b> programação</b>. Assim, me apaixonei por ela e decidir seguir caminho, criando diversos projetos individuais. 
                        <br/><Recuo/>Aprendendo cada vez mais e decido a utilizar novas tecnologias, conheci o <b>mundo 3D</b> e sua infinidade de aplicações. 
                        Com ela crie várias ilustrações gráficas em um perfil no instragram dedicado chamado <b>Alquemist</b>. 
                        <br/><Recuo/>Com todo o conhecimento adquirido, ministrei um minicurso de games focado no <b>Unity3D</b> e <b>Blender</b>, 
                        e porteriormente outro minicurso de <b>impressão 3D</b>.
                    </CardInfo>
                    <CardInfo icon={logoIfpi} borderColor="#b60099" title="ANÁLISTA E DESENVOLVEDOR" subtitle="Instituto Federal do Piauí" location="Corrente-PI" period="2021 a 2023">
                        <Recuo/>Com este curso superior, aperfeiçoei e aprofundei meus conhecimentos em programação, 
                        abrindo um novo horizonte em áreas como <b>Aplicações Web, servidores, Banco de Dados, Metodologias Ágeis, 
                        Data Science e Inteligência Artificial.</b> 
                        <br/><Recuo/>O design foi uma ferramenta chave para me destacar em vários projetos e 
                        colaborações externas, como o <b>Agroshow</b> e <b>Agrosul Piauí</b>. Ela também foi a virada de chave em minha vida trazendo novas técnicas.
                        <br/><Recuo/>Além disso, durante o curso, entrei no ramo das <b>aplicações móveis</b>, 
                        o que trouxe a possibilidade de desenvolver soluções rápidas para diversos problemas, como o <b>Agrowise</b>.
                    </CardInfo>
                </>
            ]
        },
        {
            nome: "Fale comigo",
            pages: [
                <>
                    <Header>
                        <Perfil width={300} titulo="Fale comigo" flutuante />
                    </Header>
                    <div id="contacts">
                        {Contacts.freelancer}
                        {Contacts.linkedin}
                        {Contacts.github}
                        {Contacts.instagram}
                        {Contacts.email}
                    </div>
                </>
            ]
        },
    ];

    function setNormalizeChapter(value: number) {
        if (value < 0) {
            setChapter(0);
        } else if (value >= chapters.length) {
            setChapter(chapters.length - 1);
        } else {
            setChapter(value);
        }
    }

    function setNormalizePage(value: number) {
        if (value < 0) {
            if (chapter === 0) {
                setPage(0);
            } else {
                setNormalizeChapter(chapter - 1);
                setPage(chapters[chapter - 1].pages.length - 1);
            }
        } else if (value > chapters[chapter].pages.length - 1) {
            if (chapter === chapters.length - 1) {
                setPage(chapters[chapter].pages.length - 1);
            } else {
                setNormalizeChapter(chapter + 1);
                setPage(0);
            }
        } else {
            setPage(value);
        }
    }

    useEffect(() => {
        updateSize();
        const rolar = (event: WheelEvent) => {
            if (event.deltaY < 0) {
                setNormalizePage(page - 1);
            } else if (event.deltaY > 0) {
                setNormalizePage(page + 1);
            }
        }
        // const parallax = (event: MouseEvent) => {
        //     const background = document.body;
        //     const moveX = (event.clientX / window.innerWidth) * 10;
        //     const moveY = (event.clientY / window.innerHeight) * 10;
        //     background.style.backgroundPosition = `${moveX}% ${moveY}%`;
        // }
        document.addEventListener('wheel', rolar);
        window.addEventListener('resize', updateSize);
        // document.addEventListener('mousemove', parallax);
        return () => {
            document.removeEventListener('wheel', rolar);
            // document.removeEventListener('mousemove', parallax);
        };
    });

    return (
        <>
            { drawer ?
                <Drawer >
                    <Options />
                    <Menu position={chapter} itensMenu={chapters.map((chapter) => chapter.nome)} onActive={(value: number) => {
                        setNormalizeChapter(value);
                        setPage(0);
                    }} />
                </Drawer> :
                <>
                    <Options />
                    <Menu position={chapter} itensMenu={chapters.map((chapter) => chapter.nome)} onActive={(value: number) => {
                        setNormalizeChapter(value);
                        setPage(0);
                    }} />
                </>
            }
            
            <Book chapter={chapter}>
                {chapters.map((chapter, idC) =>
                    <Chapter key={idC} page={page}>
                        {chapter.pages.map((page, idP) =>
                            <Page key={idP}>
                                {page}
                            </Page>)}
                    </Chapter>)}
            </Book>
            <Timeline value={page} length={chapters[chapter].pages.length} setPage={setNormalizePage} />
        </>
    );
}

export default App; 