import './style.css';
import Dropdown, { DropItem } from '../Dropdown';
import FBr from "../../assets/images/flags/flag_br.png";
// import FEn from "../../assets/images/flags/flag_en.png";
// import FEs from "../../assets/images/flags/flag_es.png";
// import FCn from "../../assets/images/flags/flag_cn.png";
// import FIn from "../../assets/images/flags/flag_in.png";
import Switch from '../Switch';

const listLang: DropItem[] = [
    { id: 0, icon: FBr, label: "Português" },
    // { id: 1, icon: FEn, label: "Inglês" },
    // { id: 2, icon: FEs, label: "Espanhol" },
    // { id: 3, icon: FCn, label: "Chinês" },
    // { id: 4, icon: FIn, label: "Idiano" },
];

function Options (){
    
    return (
        <span id="settings">
            <Dropdown selectedId={0} children={listLang}/>
            <Switch 
                onEnabled={() => {
                        document.documentElement.style.setProperty('--inversor', '0');
                        document.documentElement.style.setProperty('--cor', 'white');
                        document.documentElement.style.setProperty('--corFundo', '#061828');
                }}
                onDisabled={() => {
                    document.documentElement.style.setProperty('--inversor', '1');
                    document.documentElement.style.setProperty('--cor', '#061828');
                    document.documentElement.style.setProperty('--corFundo', 'white');
                }}
                enableText="Escuro"
                disableText="Claro"
            />
        </span>
    );
}

export default Options;