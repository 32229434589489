import { ReactNode } from "react";
import "./style.css";

interface HeaderProps {
    children: ReactNode
}

export function Header({children}: HeaderProps){
    return (
        <header id="cabecalho">
            {children}
        </header>
    );
}