import { useState } from "react";
import "./style.css";

interface SwitchProps {
    onEnabled: () => void,
    onDisabled: () => void,
    enableText: string,
    disableText: string,
}

function Switch ({ onEnabled, onDisabled, enableText, disableText, ...rest }: SwitchProps){
    
    const [active, setActive] = useState(true);

    function toggle(value: boolean) {
        if(value){
            onEnabled();
        }
        else {
            onDisabled();
        }
        setActive(value);
    }

    return ( 
        <div id="toggle" className={active ? "dark" : ""} onClick={() => toggle(!active)} {...rest}>
            {active ? enableText : disableText }
        </div>
    );
}

export default Switch;