import "./style.css";
import ArrowBack from "./images/ArrowBack.svg";
import ArrowForward from "./images/ArrowForward.svg";

interface TimelineProps {
    value: number,
    length: number,
    setPage: (value: number) => void,
}

export default function Timeline({value, length, setPage}: TimelineProps){
    const percent = ((value + 1) / length) * 100;
    return (
        <div id="progress">
            <span className="botaoDirecional left" onClick={() => setPage(value - 1)}>
                <img src={ArrowBack} alt="" />
            </span>
            <span className="botaoDirecional right" onClick={() => setPage(value  + 1)}>
                <img src={ArrowForward} alt="" />
            </span>
            <span id="textHelper">
                {value + 1} de {length}
            </span>
            <div id="line" style={{width: percent + "%"}}></div>
        </div>
    );
}