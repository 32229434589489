import './style.css';

interface TextsEffectProps {
    texts: string[],
    velocity: number,
    fontSize?: number,
    fontWeight?: string,
    color?: string,
}

export default function TextsEffect({texts, velocity, fontSize, fontWeight, color }: TextsEffectProps) {
    if(texts.length === 1){
        return (
            <div className="writing" style={{
                color: color,
                fontSize: fontSize,
                fontWeight: fontWeight,
                width: texts[0].length + "ch",
                animation: "typing " + (texts[0].length * velocity) + 
                "s  steps(" + texts[0].length + ") forwards"
            }}>
                {texts}
            </div>
        );
    }
    else if(texts.length > 1){
        const elements = [];
        let sumTime = 0;
        for (let index = 0; index < texts.length; index++) {
            elements.push(
                <div key={index} className="writing" style={{
                    color: color,
                    fontSize: fontSize,
                    fontWeight: fontWeight,
                    width: texts[index].length + "ch",
                    animation: "typing " + (texts[index].length * velocity) +
                    "s " + sumTime + "s steps(" + texts[index].length + 
                    ") forwards"
                }}>
                    {texts[index]}
                </div>
            );
            sumTime += texts[index].length * velocity;
        }
        return (
            <div className='textsEffects' style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                {elements}
            </div>
        );
    }
    else {
        return (<></>);
    }
    
}