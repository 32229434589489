import ExpandLess from "./svgs/Expand_less.svg";
import ExpandMore from "./svgs/Expand_more.svg";
import { useState } from "react";
import "./style.css";

export interface DropItem {
    id: number,
    icon?: string,
    label: string,
    onSelected?: () => void,
};

interface DropdownProps{
    selectedId: number,
    children: DropItem[],
}

function Dropdown ({selectedId, children}: DropdownProps){
    const [selected, setSelected] = useState(selectedId);
    const [open, setOpen] = useState(false);

    return (
        <span id="dropdown">
            <div id="selected" className="flaglang" onClick={() => setOpen(!open)}>
                {open ? <img className="arrow" src={ExpandMore} alt="Expand More"/> : <img className="arrow" src={ExpandLess} alt="Expand Less"/>}
                <img src={ children[selected].icon } alt="flag"/>
                { children[selected].label }
            </div>
            { open ? <ul>
                 {children.map((item) => {
                    if(item.id !== selected) {
                        return (
                            <li key={"itemDrop" + item.id} className="flaglang" onClick={() => { setSelected(item.id); setOpen(false); }}>
                                <img src={item.icon} alt="flag"/>
                                {item.label}
                            </li>
                        );
                    }
                    else {
                        return null;
                    }
                })}
            </ul> : null}
        </span>
    );
}

export default Dropdown;