import "./style.css";
import ImgFreelancer from "./contacts/freelancer.png";
import ImgLinkedin from "./contacts/linkedin.png";
import ImgWhatsapp from "./contacts/whatsapp.png";
import ImgInstagram from "./contacts/instragram.png";
import ImgGithub from "./contacts/github.png";

export const Contacts = {
    linkedin: <Contact icon={ImgLinkedin} 
    name="Linkedin" value="Ismael Guedes" link="https://www.linkedin.com/in/ismael-guedes-ba3b3729a" color="#0967C1" />,
    whatsapp: <Contact icon={ImgWhatsapp} 
    name="Whatsapp" value="(89) 9 9999-9999" link="" color="#00D95F" />,
    email: <Contact icon={ImgInstagram} 
    name="Email" value="ismael.basic@gmail.com" link="mailto:ismael.basic@gmail.com" color="#FF5C00"/>,
    instagram: <Contact icon={ImgInstagram} 
    name="Instagram" value="@ismael_vguedes" link="https://www.instagram.com/ismael_vguedes" color="#DB00FF"/>,
    freelancer: <Contact icon={ImgFreelancer} 
    name="Freelancer" value="Ismael Guedes" link="https://freelancer.com.br/freelancers/ismael-0LKIBR18" color="#FFFFFF" textColor="#222"/>,
    github: <Contact icon={ImgGithub} 
    name="Github" value="ismaelvguedes" link="https://github.com/ismaelvguedes" color="#323232"/>,
}

interface ContactProps {
    icon: string,
    name: string,
    value: string,
    link: string,
    color: string,
    textColor?: string,
}

export default function Contact ({icon, name, value, link, color, textColor = "white"}: ContactProps){
    return (
        <div className="contact" style={{backgroundColor: color}} >
            <img src={icon} alt={name} />
            <span className="line" style={{backgroundColor: textColor}}/>
            <div>
                <h2 style={{color: textColor}}>{name}</h2>
                <h4 style={{color: textColor}}>{value}</h4>
                <a className="link" href={link} target="blank" style={{color: textColor}}>Acesse aqui!</a>
            </div>
        </div>
    )
}