import "./style.css";
import ImgPython from "./technologies/python.png";
import ImgDjango from "./technologies/django.png";
import ImgHtml from "./technologies/html.png";
import ImgCss from "./technologies/css.png";
import ImgDatabase from "./technologies/database.png";
import ImgDart from "./technologies/dart.png";
import ImgFirebase from "./technologies/firebase.png";
import ImgFlutter from "./technologies/flutter.png";
import ImgJavascript from "./technologies/javascript.png";
import ImgReact from "./technologies/react.png";
import ImgTypescript from "./technologies/typescript.png";
import ImgMySql from "./technologies/mysql.png";
import ImgPostgresql from "./technologies/postgresql.png";
import ImgScikitLearn from "./technologies/scikitlearn.png";
import { ReactNode } from "react";
import Tooltip from '@mui/material/Tooltip';

export const IconsTech = {
    python: <IconTech image={ImgPython} name="Python" color="#ffde58" 
        hrefInfos="https://pt.wikipedia.org/wiki/Python">
        Python é uma linguagem de programação de alto nível,<br/> interpretada de script, imperativa, orientada a objetos,<br/> funcional, de tipagem dinâmica e forte.
    </IconTech>,
    django: <IconTech image={ImgDjango} name="Django" color="#246F52"
        hrefInfos="https://pt.wikipedia.org/wiki/Django_(framework_web)">
        Django é um framework para desenvolvimento<br/> rápido para web, escrito em Python, que utiliza<br/> o padrão model-template-view (MTV).
    </IconTech>,
    html: <IconTech image={ImgHtml} name="Html" color="#FC490B"
        hrefInfos="https://pt.wikipedia.org/wiki/HTML">
        HTML é uma linguagem de marcação utilizada na<br/> construção de páginas na Web. Documentos HTML<br/> podem ser interpretados por navegadores.
    </IconTech>,
    css: <IconTech image={ImgCss} name="Css" color="#2196F3" 
    hrefInfos="https://pt.wikipedia.org/wiki/Cascading_Style_Sheets">
        Cascading Style Sheets (abreviado CSS) é <br/>um mecanismo para adicionar estilos (cores,<br/> fontes, espaçamento, etc.) a uma página web.
    </IconTech>,
    sqlite: <IconTech image={ImgDatabase} name="Sqlite" color="#B70069"
    hrefInfos="https://pt.wikipedia.org/wiki/SQLite">
        SQLite é uma biblioteca em linguagem C que<br/> implementa uma base de dados SQL embutida.
    </IconTech>,
    dart: <IconTech image={ImgDart} name="Dart" color="#008FFF"
    hrefInfos="https://pt.wikipedia.org/wiki/Dart_(linguagem_de_programa%C3%A7%C3%A3o)">
        Dart (originalmente denominada Dash) é uma<br/> linguagem de script voltada à web desenvolvida<br/> pela Google.
    </IconTech>,
    firebase: <IconTech image={ImgFirebase} name="Firebase" color="#FFA000"
    hrefInfos="https://en.wikipedia.org/wiki/Firebase">
        Firebase é um conjunto de serviços de back-end<br/> de computação em nuvem e plataformas de<br/> desenvolvimento de aplicativos fornecidos <br/>pelo Google . 
    </IconTech>,
    flutter: <IconTech image={ImgFlutter} name="Flutter" color="#5AB6FF"
    hrefInfos="https://pt.wikipedia.org/wiki/Flutter">
        Flutter é um kit de desenvolvimento de software de interface<br/> de usuário (toolkit e framework), de código aberto, criado<br/> pela empresa Google em 2015, baseado na linguagem<br/> de programação Dart.
    </IconTech>,
    javascript: <IconTech image={ImgJavascript} name="JavaScript" color="#D3B62A"
    hrefInfos="https://pt.wikipedia.org/wiki/JavaScript">
        JavaScript (frequentemente abreviado como JS) é uma <br/>linguagem de programação interpretada estruturada,<br/> de script em alto nível com tipagem dinâmica fraca<br/> e multiparadigma 
    </IconTech>,
    react: <IconTech image={ImgReact} name="React" color="#61DAFB"
    hrefInfos="https://pt.wikipedia.org/wiki/React_(JavaScript)">
        O React (também denominado React.js ou ReactJS) é uma <br/>biblioteca front-end JavaScript de código aberto com<br/> foco em criar interfaces de usuário em páginas web.
    </IconTech>,
    typescript: <IconTech image={ImgTypescript} name="Typescript" color="#2D7BD0"
    hrefInfos="https://pt.wikipedia.org/wiki/TypeScript">
        TypeScript é uma linguagem de programação de código aberto<br/> desenvolvida pela Microsoft. É um superconjunto sintático<br/> estrito de JavaScript e adiciona tipagem estática<br/> opcional à linguagem.
    </IconTech>,
    mysql: <IconTech image={ImgMySql} name="MySql" color="#e68f00"
    hrefInfos="https://pt.wikipedia.org/wiki/MySQL">
        O MySQL é um sistema de gerenciamento de banco de dados (SGBD),<br/> que utiliza a linguagem SQL (Linguagem de Consulta Estruturada, do inglês<br/> Structured Query Language) como interface.
    </IconTech>,
    postgresql: <IconTech image={ImgPostgresql} name="Postgresql" color="#336791"
    hrefInfos="https://pt.wikipedia.org/wiki/PostgreSQL">
        PostgreSQL é um sistema gerenciador de banco de dados<br/> objeto relacional (SGBD), desenvolvido como projeto de código aberto.
    </IconTech>,
     scikitlearn: <IconTech image={ImgScikitLearn} name="Scikit-Learn" color="#f89939"
     hrefInfos="https://pt.wikipedia.org/wiki/Scikit-learn">
        A scikit-learn é uma biblioteca de aprendizado de máquina<br/> de código aberto para a linguagem de programação Python.
     </IconTech>,
}

interface IconTechProps{
    image: string,
    name: string,
    color: string,
    hrefInfos: string,
    children: ReactNode,
}

export default function IconTech({image, name, color, hrefInfos, children}: IconTechProps){
    function openLink(url: string): void {
        window.open(url, '_blank');
    }
    return (
        <Tooltip title={children} followCursor arrow>
            <div className="technology" style={{color: color}} onClick={() => openLink(hrefInfos)}>
                    <img src={image} alt="name" height={50} width={"auto"}/>
                {name}
            </div>
        </Tooltip>
    );
}