import { useEffect, useState } from "react";
import './style.css'

interface MenuProps {
    position: number,
    itensMenu: string[],
    onActive: (value: number) => void,
}

function Menu({ position, itensMenu, onActive }: MenuProps) {
    
    const [selected, setSelected] = useState(0);

    function moveArrow(elementId: string, id: number) {
        let node = document.getElementById("items-menu")?.getBoundingClientRect();
        let target = document.getElementById(elementId)?.getBoundingClientRect();
        let arrow = document.getElementById("arrow");
        if(node && target && arrow) {
            let posicao = target.top + arrow.scrollHeight - node.top;
            arrow.style.top = posicao + "px";
            arrow.style.transition = "1s";
            setSelected(id);
        }
    }

    useEffect(() => {
        moveArrow(itensMenu[position], position);
    });

    return (      
        <span id="menu">
            <nav>
                <span id="arrow"></span>
                <ul id="items-menu">
                    {itensMenu.map((item, id) => {
                        if(id === selected) return <li id={item} key={item} className="selected" onClick={() => { onActive(id); moveArrow(item, id) }}>{item}</li>;
                        else return <li id={item} key={item} onClick={() => { onActive(id); moveArrow(item, id) }}>{item}</li>
                    })}
                </ul>
            </nav>
        </span>  
    );
}

export default Menu;