import { useEffect, useState } from "react";
import "./style.css";

interface SliderProps {
    width: number,
    height: number,
    timeStep: number,
    images: string[],
}

export default function Slider({width, height, timeStep, images}: SliderProps){
    const [slide, setSlide] = useState(0);
    function normalizeSlide(value: number, min: number, max: number) {
        if(value > max){
            return min;
        }
        else {
            return value;
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setSlide(normalizeSlide(slide + 1, 0, images.length - 1));
        }, timeStep * 1000); 

        return () => clearInterval(interval);
    }, [timeStep, images, slide]);

    return (
        <section className="slide" style={{
            width: width + 40,
            height: height + 20,
        }}>
            <ul id="images">
                {
                    images.map((image, id) => { 
                        let recalculate = id - slide;
                        if (recalculate < 0) recalculate = images.length + recalculate;
                        const position = (40 / images.length) * recalculate;
                        return (
                            <li 
                                key={"imgSlider_" + id} 
                                style={{
                                    width: width,
                                    height: height,
                                    transform: "translate3d(" + position + "px, " + position + "px, 0px)",
                                    opacity: "calc(1 / " + recalculate + ")",
                                    filter: "blur(calc(0.5px * " + recalculate + "))",
                                    zIndex: "calc(50 / " + recalculate + ")",
                                }}
                            >
                                <img width={width} height={height} src={image} alt={"imgSlider_" + id}/>
                            </li>
                        );                        
                    })
                }
            </ul>
            <ul id="buttons">
                {images.map((image, id) => 
                    <li key={"buttonSlide_" + id} className={id === slide ? "select" : ""} onClick={() => setSlide(id)}></li>
                )}
            </ul>
        </section>
    );
}