import "./style.css";
import React, { ReactNode } from "react";

interface TopicoProps {
    children: ReactNode,
}

export function Topico({ children }: TopicoProps){
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 30}}>
            {children}
        </div>
    )
}

interface PageProps {
    children: ReactNode,
}

export function Page({ children }: PageProps)  {
    return (
        <div className="page">
            {children}
        </div>
    );
}

interface ChapterProps {
    children: ReactNode,
    page: number,
}

export function Chapter({ page, children }: ChapterProps) {
    const filteredChildren = React.Children.toArray(children).filter(
        (child) => React.isValidElement(child) && child.type === Page
    );
    return (
        <div className="chapter">
            {filteredChildren[page]}
        </div>
    );
}

interface BookProps {
    chapter: number
    children: ReactNode
}

export function Book({ chapter, children }: BookProps){
    const filteredChildren = React.Children.toArray(children).filter(
        (child) => React.isValidElement(child) && child.type === Chapter
    );
    return (
        <>{filteredChildren[chapter]}</>
    );
}