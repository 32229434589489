import { ReactNode } from "react";
import "./style.css";

interface CardInfoProps {
    icon: string,
    borderColor: string,
    title: string,
    subtitle: string,
    location: string,
    period: string,
    children: ReactNode,
}

export default function CardInfo({icon, borderColor, title, subtitle, location, period, children}: CardInfoProps){
    return (
        <div className="cardInfo" style={{borderTop: "15px solid " + borderColor}}>
            <header>
                    <img src={icon} alt="logo"/>
                    <div id="texts">
                        <h2><b>{title}</b></h2>
                        <h3><b>{subtitle}</b></h3>
                        <div>
                            {location}
                            <b>{period}</b>
                        </div>
                    </div>
            </header>
            <br/>
            {children}
        </div>
    );
}